<template>
  <div class="ui-horizontal-carousel">
    <button
      class="fake-slick-arrow slick-arrow slick-prev slick-disabled"
      type="button"
    />
    <VueSlickCarousel v-bind="settings">
      <slot />
    </VueSlickCarousel>
    <button
      class="fake-slick-arrow slick-arrow slick-next slick-disabled"
      type="button"
    />
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  name: 'UiHorizontalCarousel',
  components: { VueSlickCarousel },
  data: () => ({
    settings: {
      arrows: true,
      infinite: false,
      slidesToScroll: 4,
      slidesToShow: 4,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToScroll: 2,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToScroll: 3,
            slidesToShow: 3,
          },
        },
      ],
    },
  }),
};
</script>

<style lang="scss" scoped>
$arrow-width: 2rem;
$arrow-icon-font-weight: 900;
$carousel-vertical-padding: 0.25rem;

.ui-horizontal-carousel ::v-deep {
  position: relative;
  padding-left: $arrow-width;
  padding-right: $arrow-width;

  .fake-slick-arrow {
    z-index: 100 !important;

    &.slick-prev {
      left: 0px !important;
    }

    &.slick-next {
      right: 0px !important;
    }
  }

  .slick-list {
    padding-top: $carousel-vertical-padding;
    padding-bottom: $carousel-vertical-padding;
  }

  .slick-arrow {
    height: 100%;
    width: $arrow-width;
    border-radius: 0.25rem;
    border-color: #2db782;
    background-color: #2db782;
    z-index: 1000;

    &.slick-disabled {
      background-color: #d9e5df;
      border-color: #d9e5df;
    }

    &.slick-prev {
      left: -$arrow-width;
    }

    &.slick-next {
      right: -$arrow-width;
    }
  }

  .slick-prev {
    &::before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: $arrow-icon-font-weight;
      content: '\f323';
    }
  }

  .slick-next {
    &::before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: $arrow-icon-font-weight;
      content: '\f324';
    }
  }
}
</style>
