<template>
  <UiHorizontalCarousel>
    <div
      v-for="(item, i) in menuWithParams"
      :key="i"
      cols="2"
      :style="{ width: '11rem' }"
    >
      <div class="d-flex flex-column align-items-center">
        <b-avatar
          size="4rem"
          :style="{ 'background-color': item.color }"
          :to="item.to"
        >
          <img v-if="!!item.img" :src="item.img" />
          <em v-else :class="[item.icon, 'fa-2x fa-fw']" />
        </b-avatar>
        <span class="mt-2 text-center px-4" v-text="item.title" />
      </div>
    </div>
  </UiHorizontalCarousel>
</template>

<script>
import UiHorizontalCarousel from '@/components/ui/UiHorizontalCarousel';
import storeMixin from '@quisit/mipe-utils/mixins/storeGenerator';

const ROUTE_PARAM_NAME = 'studentSieId';

export default {
  name: 'StudentMenu',
  components: { UiHorizontalCarousel },
  mixins: [storeMixin],
  props: {
    studentSieId: { type: [String, Number], default: null },
  },
  data: () => ({}),
  computed: {
    menu() {
      return [
        {
          title: 'Información del Estudiante',
          icon: 'fas fa-file-user',
          color: '#FF5B5C',
          to: { name: 'student-information' },
          visible: this.userHasPermissions(
            'parents.menu.studentinformationcard'
          ),
        },
        {
          title: 'PEI',
          icon: 'fas fa-user-edit',
          color: '#2DB782',
          to: { name: 'student-pei-history' },
          visible: this.userHasPermissions('parents.menu.peicard'),
        },
        {
          title: 'Evaluaciones',
          icon: 'fas fa-file-edit',
          color: '#5E81F4',
          to: { name: 'student-evaluations_history' },
          visible: this.userHasPermissions('parents.menu.evaluationcard'),
        },
        {
          title: 'Historial de Terapias',
          icon: 'fas fa-file-alt',
          color: '#3F5B60',
          to: { name: 'student-service_history' },
          visible: this.userHasPermissions('parents.menu.teraphyhistorycard'),
        },
        {
          title: 'Solicitud de Remedio Provisional',
          icon: 'fas fa-user-circle',
          color: '#E3621D',
          to: { name: 'provisional-remedy' },
          visible: this.userHasPermissions('parents.menu.remprorequestcard'),
        },
        {
          title: 'Historial de Contratos',
          icon: 'fas fa-history',
          color: '#C14F65',
          to: { name: 'student-contracts_history' },
          visible: this.userHasPermissions('parents.menu.contracthistorycard'),
        },
        {
          title: 'Referido de Evaluaciones',
          icon: 'fas fa-user-edit',
          color: '#84C14F',
          to: { name: 'student-referral_evaluations' },
          visible: this.userHasPermissions(
            'parents.menu.referralforevaluationcard'
          ),
        },
        {
          title: 'Historial de Querellas',
          icon: 'fas fa-file-contract',
          color: '#2DB7AC',
          to: { name: 'student-complaints_history' },
          visible: this.userHasPermissions('parents.menu.complainshistorycard'),
        },
        {
          title: 'Historial de Registro de Visitas',
          icon: 'fas fa-calendar-day',
          color: '#4F88C1',
          to: { name: 'student-visits_history' },
          visible: this.userHasPermissions(
            'parents.menu.visithistoryhistorycard'
          ),
        },
        {
          title: 'Asistencia de BECA',
          icon: 'fas fa-car',
          color: '#E04B4B',
          to: { name: 'scholarship-attendance_history' },
          visible: this.userHasPermissions('parents.menu.scholarshipcard'),
        },
        {
          title: 'Facturación de Reembolso y Pago Directo',
          icon: 'fas fa-file-invoice-dollar',
          color: '#1E8C9A',
          to: { name: 'pay-refund' },
          visible: this.userHasPermissions('parents.menu.paymentcard'),
        },
        {
          title: 'Historial de Consulta de Ubicación',
          icon: 'fas fa-map-marked-alt',
          color: '#FF7058',
          to: { name: 'history-location' },
          visible: this.userHasPermissions('parents.menu.locationhistorycard'),
        },
        {
          title: 'Informe de Progreso',
          img: require('@/assets/img/file-chart-column.svg'),
          color: '#FF9858',
          to: { name: 'academic-process-inform' },
          visible: true,
        },
        {
          title: 'Historial Acceso Expediente',
          icon: 'fas fa-history',
          color: '#FFB802',
          to: { name: 'history-access-file' },
          visible: true,
        },
      ];
    },
    menuWithParams() {
      return this.menu
        .filter((x) => x.visible)
        .map((x) => ({
          ...x,
          to: {
            ...x.to,
            params: { [ROUTE_PARAM_NAME]: this.studentSieId, from: 'menu' },
          },
        }));
    },
  },
};
</script>
