<template>
  <div
    :id="id"
    class="modal fade"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="id"
    aria-hidden="true"
  >
    <ValidationObserver ref="messageObserver">
      <form novalidate @submit.prevent="onContinue">
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <div slot="header">
                <div class="tag-badge">
                  <span class="dot text-center">
                    <em
                      class="orange-icon orange-icon-badge-align fa fa-info-circle"
                    />
                  </span>
                  <span class="tag-badge-title py-1 pl-2">
                    {{ message.titleDescription }}
                  </span>
                </div>
              </div>
            </div>
            <div class="modal-body">
              <div class="container">
                <span class="message-date">
                  {{ message.createDate | formatDate }}
                </span>
                <div
                  class="mt-2 message-description"
                  v-html="message.messageDescription"
                />
                <div v-if="message.documentId" class="mt-4">
                  <span
                    class="item-download-file"
                    @click="downloadFile(message.documentId)"
                  >
                    <em class="fa fa-paperclip" />
                    <span>Descargar documento</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row mx-4">
              <div class="dropdown-divider mt-0 pb-2 w-100 ml-1 mr-1" />
              <div class="col-md-6 text-left mt-2">
                <ValidationProvider
                  v-if="showCertification"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <div
                    class="custom-control custom-checkbox checkbox-top-spacing ml-n2"
                  >
                    <input
                      id="chkCertified"
                      v-model="message.isCertified"
                      type="checkbox"
                      name="chkCertified"
                      class="custom-control-input"
                      @change="onChangeCerfified"
                    />
                    <label
                      for="chkCertified"
                      class="custom-control-label font regular"
                      :class="[{ 'has-error': errors[0] }]"
                    >
                      <span><em class="ic-check" /></span>
                      Certifico que he leído este mensaje.
                    </label>
                    <error
                      v-if="errors[0]"
                      message="Campo Requerido."
                      variant="no-border ml-n1"
                    />
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-md-6 col-sm-12 text-right action-container">
                <action-button
                  type="submit"
                  class="ml-2 px-4"
                  variant="outline-success close-border mr-n3"
                >
                  Continuar
                </action-button>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
//third party
import $ from 'jquery';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

//libs component
import ActionButton from 'ActionButton';
import Error from 'custom-error';

//services
import _generalService from '@/services/pei/general';

//utils
import alertMixin from '@quisit/mipe-utils/mixins/alert.mixin';
import sessionManager from '@/utils/managers/sessionManager';

//constants
import locationMessageCode from '@/utils/constants/messageLocationCode.js';
import sessionKeys from '@/utils/constants/sessionKeys.js';

export default {
  name: 'ImmediateMessagePopup',
  mixins: [alertMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    Error,
    ActionButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => ({
    message: {},
    currentIndex: 0,
  }),
  computed: {
    showCertification() {
      return (
        this.message.messageLocationCode === locationMessageCode.InicioConAcuse
      );
    },
  },
  methods: {
    loadNextOrClose() {
      this.setCurrentIndex();
      if (this.currentIndex === -1) {
        this.closePopup();
      } else {
        this.message = this.items[this.currentIndex];
      }
    },
    setMessageToSession(id) {
      sessionManager.set(sessionKeys.KEY_MESSAGE_READ, [
        ...sessionManager.get(sessionKeys.KEY_MESSAGE_READ, '[]'),
        id,
      ]);
    },
    openPopup() {
      this.message = this.items[this.currentIndex];
      let element = this.$el;
      $(element).modal('show');
    },
    closePopup() {
      let element = this.$el;
      $(element).modal('hide');
    },
    onChangeCerfified() {
      if (this.message.isCertified === false) {
        this.message.isCertified = null;
      }
    },
    async onContinue() {
      if (this.showCertification) {
        const isValid = await this.$refs.messageObserver.validate();
        if (!isValid) {
          this.ShowToast(
            'Alerta',
            'Favor de certificar que ha leído mensaje.',
            'error'
          );
        } else {
          this.$emit('message-marked-as-read', this.message.messageId);
          this.setMessageToSession(parseInt(this.message.messageId));
          this.loadNextOrClose();
        }
      } else {
        this.setMessageToSession(parseInt(this.message.messageId));
        this.loadNextOrClose();
      }
    },
    downloadFile(id) {
      if (id) {
        _generalService.downloadDocumentByAttachmentId(id);
      }
    },
    setCurrentIndex() {
      let readMessageIds = sessionManager.get(
        sessionKeys.KEY_MESSAGE_READ,
        '[]'
      );
      this.currentIndex = this.items.findIndex(
        (m) => !readMessageIds.includes(m.messageId)
      );
    },
    async init() {
      if (this.items.length > 0) {
        this.setCurrentIndex();
        if (this.currentIndex > -1) {
          this.openPopup();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .message-description img {
  width: 100%;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}

.thead-property {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px 5px 0px 0px;
}

.thead-table-modal {
  border-radius: 5px 5px 0px 0px !important;
  display: table-caption;
}

.td-aligned {
  padding-right: 0px;
  padding-left: 32px;
}

.table-h {
  height: 480px;
}
.bg-dark {
  border-collapse: collapse;
  background-color: #3f5b60 !important;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  color: #ffffff;
  font-weight: 400;
}
.tdDate {
  width: 33.5%;
}

.textNorm {
  font-weight: 400;
}

.content-table-striped-modal {
  margin-top: 0px;
}

.tag-badge {
  display: inline-block;
  padding-right: 1em;
  height: 42px;
  background: transparent linear-gradient(90deg, #ffffff 0%, #e8e8e8 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 26px;
  cursor: pointer;
  margin-bottom: 10px;
}

.dot {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: inline-block;
  /* top: 268px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding-top: 9px;
}
.orange-icon {
  color: #ff7058;
}

.orange-icon-badge-align {
  vertical-align: top;
  font-size: 29px;
  margin-top: -4px;
}

.tag-badge-title {
  text-align: left;
  font: Bold 18px/28px Lato;
  letter-spacing: 0;
  color: #11291b;
  display: inline-block;
}

.close-border {
  border: 1px solid #2db782;
  border-radius: 50px;
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.6em;
  text-align: center;
  width: 7.1875rem;
  height: 2.6875rem;
  cursor: pointer;
  padding-right: 100px !important;
}

.close-ico {
  color: #2db782;
  margin-right: 3px;
}

.modal-header {
  padding: 0.5rem 0rem 0rem 1.5rem;
  box-shadow: 0px 10px 40px -20px black;
}
.cursor-pointer {
  cursor: pointer;
}

.message-date {
  text-align: left;
  font: Regular 12px/21px Lato;
  color: #727272;
}

.has-error::before {
  border: 1px solid #f00;
  border-radius: 5px;
  background-color: #f7d7da;
}

.item-download-file {
  display: flex;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2db782;
  cursor: pointer;
  margin-top: 3px;
  em {
    margin-right: 5px;
    margin-top: 3px;
  }
  span {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 768px) {
  .tag-badge {
    display: flex;
    flex-direction: row;
    height: auto;
  }

  .action-container {
    display: flex;
    justify-content: center;
    margin: 0px;
    button {
      margin-right: 0px !important;
    }
  }
}
</style>
