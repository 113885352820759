<template>
  <b-card v-if="student" border-variant="light" class="mt-4 bg-light-gray">
    <slot name="header" />

    <b-card border-variant="light">
      <b-row>
        <b-col cols="12" md="5">
          <div class="h-100 w-100 d-flex align-items-center">
            <b-avatar class="bg-gray mr-2" variant="dark" />
            <UiDetailDisplay
              label="Estudiante:"
              :text="`${student.name} - ${student.studentSieId}`"
            />
            <div
              class="h-100 ml-auto border-right d-none d-md-block"
              :style="{ width: '2px' }"
            />
          </div>
        </b-col>
        <b-col class="mt-2 mt-md-0">
          <UiDetailDisplay label="Grado:" :text="student.grade" />
        </b-col>
        <b-col class="mt-2 mt-md-0">
          <UiDetailDisplay label="Edad:" :text="student.age" />
        </b-col>
        <b-col class="mt-2 mt-md-0" cols="12" md="2">
          <div
            class="w-100 h-100 d-flex justify-content-center justify-content-md-end align-items-center"
          >
            <UiBadge
              v-if="student.status"
              :css-class="[
                `bg-${$options.filters.studentStatusColor(student.status.id)}`,
              ]"
              :text="student.status.name"
              text-class="text-dark text-wrap"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>

    <slot name="footer" />
  </b-card>
</template>

<script>
import UiDetailDisplay from '@/components/ui/UiDetailDisplay';
import UiBadge from '@/components/ui/UiBadge';

export default {
  name: 'StudentCard',
  components: {
    UiDetailDisplay,
    UiBadge,
  },
  props: {
    student: { type: Object, default: null },
  },
};
</script>

<style></style>
