export default {
  get: (name, defaultValue) =>
    JSON.parse(sessionStorage.getItem(name) ?? defaultValue),
  set: (key, value) => {
    let dataToStore = '';
    if (typeof value === 'object') {
      dataToStore = JSON.stringify(value);
    } else {
      dataToStore = value;
    }
    sessionStorage.setItem(key, dataToStore);
  },
  clear: (key) => sessionStorage.removeItem(key),
  clearAll: () => sessionStorage.clear(),
};
