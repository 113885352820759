import { http } from '@/core/api/';

const baseApiUrl = 'api/message';

export default {
  fetchMessages() {
    return http.get(`${baseApiUrl}/mesaggefordashboard`);
  },
  getMessageCount() {
    return http.get(`${baseApiUrl}/messagecount`);
  },
  resetMessageCount() {
    return http.post(`${baseApiUrl}/resetmessagecount`);
  },
  async saveReadMessage(messageId) {
    return http.post(`${baseApiUrl}/savemessageread/${messageId}`);
  },
};
