<template>
  <b-row>
    <b-col class="container-width-first" cols="12" md="9">
      <div
        v-if="showProvisionalDirectoryButton"
        class="mx-auto row provider-directory-jumbotron bg-green-light-200 mb-3 px-3 pt-3 pb-2"
      >
        <div class="col-1 d-flex align-items-start">
          <i class="fas fa-file-signature text-success fa-2x" />
        </div>
        <div class="col-8 text-gray">
          <b>Hola {{ userProfile.firstname }},</b>
          <p class="mt-3">
            Usted cuenta con solicitudes de Remedio Provisional en estatus
            Aprobado, oprima aquí para ver el listado de proveedores.
          </p>
        </div>
        <div class="col-3 d-flex justify-content-end align-items-center">
          <div class="action-container">
            <action-button
              class="btn-block-xs-only"
              i="fas fa-address-book"
              text="Ver Directorio"
              variant="success"
              @click="goProviderDirectoryView"
            />
          </div>
        </div>
      </div>

      <PageHeader
        class="header-without-breadcrumb"
        :page="{
          title: 'Estudiante(s)',
          icon: 'fas fa-user-circle',
          routes: [],
        }"
      />

      <StudentCard v-for="(student, i) in students" :key="i" :student="student">
        <template #footer>
          <StudentMenu class="mt-3" :student-sie-id="student.studentSieId" />
        </template>
      </StudentCard>
    </b-col>

    <b-col class="mt-md-2 mt-sm-2 container-width-second" cols="12" md="3">
      <div class="d-flex ">
        <PageHeader
          class="header-without-breadcrumb"
          :page="{
            title: 'Eventos',
            icon: 'fas fa-calendar',
            routes: [],
          }"
        />
        <div v-if="!isMobile" class="fill" />
        <div class="ml-auto">
          <router-link :to="{ name: 'calendar' }">
            <action-button
              i="fas fa-calendar"
              text="Ver Calendario"
              variant="outline-success"
            />
          </router-link>
        </div>
      </div>

      <MipeCalendar
        :category-list="categories"
        :event-list="events"
        :is-mobile="isMobile"
        :is-preview="true"
        @on-get-events-online="getAllData"
        @show-events="showItems"
      />
      <template>
        <UiCardListItem
          v-for="(item, i) in listevent"
          :key="i"
          border-indicator
          border-indicator-color="#21b47b"
          class="mt-4"
        >
          <b-row>
            <b-col cols="12" md="12">
              <strong>
                {{ item.dateFrom | moment('DD MMMM YYYY') }}
                <p class="sub-info mb-0">
                  {{
                    [item.hourStartFormatted, 'HH:mm']
                      | moment(HOUR_FORMAT_AM_PM)
                  }}-{{
                    [item.hourEndFormatted, 'HH:mm'] | moment(HOUR_FORMAT_AM_PM)
                  }}
                </p>
              </strong>
              <br />
              <p class="sub-info mb-0">{{ item.name }}</p>
            </b-col>
          </b-row>
        </UiCardListItem>
      </template>

      <div class="row mt-4">
        <div class="col-12 d-flex">
          <PageHeader
            class="header-without-breadcrumb"
            :page="{
              title: 'Mensajes',
              icon: 'fas fa-envelope',
              routes: [],
            }"
          />
          <div v-if="!isMobile" class="fill" />
          <div class="ml-auto">
            <router-link :to="{ name: 'message' }">
              <action-button
                i="fas fa-comments-alt"
                text="Ver Mensajes"
                variant="outline-success"
              />
            </router-link>
          </div>
        </div>
      </div>
    </b-col>

    <b-col cols="12">
      <immediate-message-popup
        id="immediateMessagePopup"
        ref="_immediateMessagePopupRef"
        :items="immediateMessages"
        @message-marked-as-read="onMarkedAsRead"
      />
    </b-col>
  </b-row>
</template>

<script>
//libs
import _ from 'lodash';
import PageHeader from 'PageHeader';
import ActionButton from 'ActionButton';
import MipeCalendar from '@quisit/mipe-calendar';

//components
import StudentCard from '@/components/StudentCard';
import StudentMenu from '@/components/StudentMenu';
import UiCardListItem from '@/components/ui/UiCardListItem';
import ImmediateMessagePopup from '@/components/home/ImmediateMessagePopup.vue';

//services
import messageApi from '@/services/api/message.api.js';
import generalService from '@/services/pei/general';

//store
import { parentModule, eventsModule } from '@/store/modules';
import { eventsModuleName } from '@/store/modules';
import store from '@/store/index';

//utils
import storeMixin from '@quisit/mipe-utils/mixins/storeGenerator';
import sessionManager from '@/utils/managers/sessionManager';

//constants
import sessionKeys from '@/utils/constants/sessionKeys';
import messageLocationCode from '@/utils/constants/messageLocationCode';
import applications from '@/utils/constants/applications';
import locationMessageCode from '@/utils/constants/messageLocationCode.js';
const INBOX_MESSAGE_CHUNK_SIZE = 3;
import { rpServiceStatus } from '@/enums/rp-service-status.js';
import { providerDirectoryType } from '@/enums/provider-directory-type.js';
import parameters from '@/utils/constants/sysParamCodeTypes/parameters';
import { validateParentHasRequestsInStatus as _validateParentHasRequestsInStatus } from '@/modules/provisional-remedy/api';
import { getByParameterName as _getByParameterName } from '@/api/sysparameter.api.js';

import { HOUR_FORMAT_AM_PM } from '@/components/ui/UiCalendar/constants';

export default {
  name: 'Students',
  components: {
    PageHeader,
    ActionButton,
    StudentCard,
    UiCardListItem,
    StudentMenu,
    MipeCalendar,
    ImmediateMessagePopup,
  },
  mixins: [storeMixin],
  computed: {
    ...parentModule.mapState(['students']),
    ...eventsModule.mapState(['dailyEvents']),
    isMobile() {
      return store.getters.getIsMobileDensity;
    },
    categories() {
      return this.$store.getters[`${eventsModuleName}/getCategories`];
    },
    events() {
      return this.$store.getters[`${eventsModuleName}/getEvents`];
    },
    canGoToTurnos() {
      return this.userHasPermissions(
        'parents.general.coordinateappointmentdeprsystem'
      );
    },
    showProvisionalDirectoryButton() {
      return this.parentHasRequestsFlag;
    },
  },
  data() {
    return {
      listevent: [],
      immediateMessages: [],
      parentHasRequestsFlag: false,
      HOUR_FORMAT_AM_PM,
    };
  },
  created() {
    this.loadMessages().then(() => {
      if (this.immediateMessages.length > 0) {
        this.$refs._immediateMessagePopupRef.init();
      }
    });
  },
  async mounted() {
    const {
      data: parentHasRequests,
    } = await _validateParentHasRequestsInStatus(rpServiceStatus.Approved);
    this.parentHasRequestsFlag = parentHasRequests;
    this.overrideInboxMessageButtonText();
  },
  methods: {
    ...eventsModule.mapActions(['getCategories', 'getEvents']),
    async getAllData(param) {
      this.listevent = [];
      await this.getEvents({ ...param, applicationId: applications.Parent });
    },
    showItems(events) {
      this.listevent = events;
    },
    async loadMessages() {
      let messagesLoaded = sessionManager.get(
        sessionKeys.KEY_MESSAGES_LOADED,
        false
      );
      if (!messagesLoaded) {
        await this.getMessageData();
      }
      this.inboxMessages = sessionManager.get(
        sessionKeys.KEY_INBOX_MESSAGES,
        '[]'
      );
      this.immediateMessages = sessionManager.get(
        sessionKeys.KEY_IMMEDIATE_MESSAGES,
        '[]'
      );
    },
    getInboxMessages() {
      return this.inboxMessages.length > 0 ? this.inboxMessages[0] : [];
    },
    downloadDocument(documentId) {
      return generalService.downloadDocumentByAttachmentId(documentId);
    },
    async getMessageData() {
      let messages = (await messageApi.fetchMessages()).data;

      let immediateMessages = messages.filter(
        (s) =>
          s.messageLocationCode === messageLocationCode.Inicio ||
          s.messageLocationCode === messageLocationCode.InicioConAcuse
      );
      sessionManager.set(sessionKeys.KEY_IMMEDIATE_MESSAGES, immediateMessages);

      let inboxMessages = _.chunk(
        messages.filter(
          (s) => s.messageLocationCode === locationMessageCode.Lateral
        ),
        INBOX_MESSAGE_CHUNK_SIZE
      );
      sessionManager.set(sessionKeys.KEY_INBOX_MESSAGES, inboxMessages);

      sessionManager.set(sessionKeys.KEY_MESSAGES_LOADED, true);
    },
    async onMarkedAsRead(messageId) {
      await messageApi.saveReadMessage(messageId);
    },
    overrideInboxMessageButtonText() {
      if (this.$refs._messagesInbox?.$children[1]?.$props) {
        this.$nextTick(() => {
          this.$set(
            this.$refs._messagesInbox.$children[1].$props,
            'text',
            'Ver Mensajes'
          );
        });
      }
    },
    async goProviderDirectoryView() {
      const { data: parameter } = await _getByParameterName(
        parameters.PublicPortalUrl
      );
      window.open(
        `${parameter.value}provider-directory/${providerDirectoryType.USPQRP}`,
        '_blank'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';

.fill {
  width: 60px;
}
.sub-info {
  color: #727272;
  font-size: 12px;
}
.provider-directory-jumbotron {
  border-radius: 10px;
}
::v-deep .header-without-breadcrumb {
  margin-bottom: 1rem;

  &.row {
    .col-12 {
      .row.mt-2.controls-wrap {
        display: none;
      }

      .dropdown-divider {
        display: none;
      }
    }
  }
}
.container-width-first {
  max-width: 75%;
}
.container-width-second {
  max-width: 25%;
}
@media only screen and (max-width: 1975px) {
  .container-width-first {
    max-width: 67%;
  }
  .container-width-second {
    max-width: 33%;
  }
}
@media only screen and (max-width: 1560px) {
  .container-width-first {
    max-width: 100%;
  }
  .container-width-second {
    max-width: 100%;
  }
}
//screen 1080P
@media (min-height: 1080px) and (min-width: 1285px) {
  .container-width-first {
    max-width: 100%;
  }
  .container-width-second {
    max-width: 100%;
  }
}
@media (min-height: 1080px) and (min-width: 1500px) {
  .container-width-first {
    max-width: 55%;
  }
  .container-width-second {
    max-width: 45%;
  }
}
@media (min-height: 1080px) and (min-width: 1600px) {
  .container-width-first {
    max-width: 65%;
  }
  .container-width-second {
    max-width: 35%;
  }
}

@media (min-height: 1080px) and (min-width: 1985px) {
  .container-width-first {
    max-width: 69%;
  }
  .container-width-second {
    max-width: 31%;
  }
}
</style>
